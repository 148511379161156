<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">

      <h5 class="text-center mb-5">Практика</h5>

      <!-- filter -->
      <section>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyLevelId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index" :value="item.id">
                {{item.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Язык обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyLanguageId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in studyLanguages" :key="index" :value="item.id">
                {{item.native_name}}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4" v-if="studyLevelId&&studyLanguageId">
          <label class="col-md-3 col-form-label">Образовательная программа</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="educationProgramId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in educationPrograms" :key="index" :value="item.id">
                {{item.education_speciality_code}} {{item.education_speciality_name}} - {{item.admission_year}}
              </option>
            </select>
          </div>
        </div>


        <div class="row mt-4" v-if="educationProgramId">
          <label class="col-md-3 col-form-label">Практика</label>
          <div v-if="!loadingPractices" class="col-md-9">
            <select class="form-control form-select" v-model="formationId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in practices" :key="index" :value="item.id">
                {{item.educationDiscipline.name}} (семестр {{item.semester}})
              </option>
            </select>
          </div>
        </div>

        <div v-if="formationId" class="my-4 text-center">
          <Button icon="pi pi-eye" label="Показать" class="p-button-rounded" :loading="loadingStudents"
                  @click="getStudents"/>
        </div>
      </section>
      <!-- end filter -->


      <!-- students -->
      <section>
        <div class="mt-5 mb-5" v-if="isShowData&&students.length">

          <DataTable :value="students" showGridlines stripedRows responsiveLayout="scroll">

            <Column header="Баркод" field="barcode"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{data?.last_name}} {{data?.first_name}}
              </template>
            </Column>
            <Column>
              <template #header>
<!--                  <div v-if="isAllStudentSelected == false">-->
<!--                      Выбрать все <input class="ms-2 form-check-input" type="checkbox" @input="checkAllStudents">-->
<!--                  </div>-->
<!--                  <div v-else>-->
<!--                      Действия-->
<!--                  </div>-->
                  Выбрать все <input class="ms-2 form-check-input" type="checkbox" @input="checkAllStudents">
              </template>
              <template #body="{data}">
                  <div v-if="data.practice == false" >
                      <input class="form-check-input" type="checkbox" :value="data.id" v-model="checkedStudents">
                  </div>
                  <div v-else>
                      <b>{{data.practice.pps_lastname}} {{data.practice.pps_firstname}}</b>
                  </div>

              </template>
            </Column>
          </DataTable>

        </div>


        <!-- bind -->
        <div v-if="checkedStudents.length" class="fixed-bottom bg-white">
          <div class="text-center p-2">
            <Dropdown v-model="ppsId" :options="pps" optionLabel="full_name" optionValue="id"
                      placeholder="Выберите руководителя"/>
            <Button icon="pi pi-link" label="Привязать"
                    :disabled="!ppsId"
                    :loading="loadingBind"
                    @click="bindPractice"
            />
          </div>
        </div>
        <!-- end bind -->

      </section>
      <!-- end students -->


    </div>
  </div>
</template>


<script>

  import {mapActions, mapState} from "vuex"


  export default {
    name: "BindPractice",
    data() {
      return {
        loading: true,

        studyLevelId: 0,
        studyLanguageId: 0,

        educationProgramId: 0,
        formationId: 0,

        loadingPractices: true,

        isShowData: false,

        loadingStudents: false,
        students: [],

        ppsId: 0,
        checkedStudents: [],

        loadingBind: false,
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('departmentPractice', ['pps', 'studyLanguages', 'educationPrograms', 'practices']),
        // isAllStudentSelected() {
        //   let studentsCount = this.students.length
        //   let practiceStudentsCount = this.students.filter(i=>i.practice == false).length
        //   if ( studentsCount == practiceStudentsCount) {
        //       return true
        //   }
        //   else {
        //       return false
        //   }
        //
        // }
    },
    watch: {
      studyLevelId() {
        this.watchLevelAndLang()
      },
      studyLanguageId() {
        this.watchLevelAndLang()
      },
      async educationProgramId() {
        this.loadingPractices = true

        this.clearPpsAndStudents()
        this.changeShowData(false)
        this.formationId = 0

        await this.GET_PRACTICES_BY_EDUCATION_PROGRAM({
          studyLevel: this.studyLevelId,
          studyLanguage: this.studyLanguageId,
          edu_prog_id: this.educationProgramId
        })

        this.loadingPractices = false
      },
      formationId() {
        this.clearPpsAndStudents()
        this.changeShowData(false)
      }
    },
    methods: {
      ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
      ...mapActions('departmentPractice', [
        'GET_STUDY_LANGUAGES',
        'GET_PPS',
        'GET_EDUCATION_PROGRAMS',
        'GET_PRACTICES_BY_EDUCATION_PROGRAM',
        'GET_STUDENTS_PRACTICE_BY_EDUCATION_PROGRAM',
        'POST_STUDENT_PRACTICES'
      ]),
      changeShowData(isVisible) {
        this.isShowData = isVisible
      },
      async watchLevelAndLang() {
        this.changeShowData(false)

        this.educationProgramId = 0
        this.formationId = 0
        this.clearPpsAndStudents()

        if (this.studyLevelId && this.studyLanguageId) {
          await this.GET_EDUCATION_PROGRAMS({
            studyLevel: this.studyLevelId,
            studyLanguage: this.studyLanguageId
          })
        }
      },
      async getStudents() {
        this.loadingStudents = true

        this.changeShowData(true)
        this.clearPpsAndStudents()
        this.students = await this.GET_STUDENTS_PRACTICE_BY_EDUCATION_PROGRAM({educationProgramId: this.educationProgramId, formationId: this.formationId})

        this.loadingStudents = false
      },
      clearPpsAndStudents() {
        this.ppsId = 0
        this.checkedStudents = []

        this.students = []
      },
      checkAllStudents(e) {
        this.checkedStudents = e.target.checked ? this.students.filter(i=> i.practice == false).map(i => i.id) : []
      },
      async bindPractice() {
        this.loadingBind = true
        const form = this.checkedStudents.map(id => ({
          student_id: id,
          pps_id: this.ppsId,
          formation_id: this.formationId
        }))

          console.log(form, 'bindPractice form')
        const res = await this.POST_STUDENT_PRACTICES(form)

        if (res) {
          await this.getStudents()
          this.clearPpsAndStudents()
          this.$message({title: 'Успешно привязано'})
        }

        this.loadingBind = false
      }
    },
    async mounted() {
      await this.GET_STUDY_LEVELS()

      await this.GET_STUDY_LANGUAGES()
      await this.GET_PPS()

      this.loading = false
    },
  }
</script>

<style scoped>

</style>